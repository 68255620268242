module.exports = [{
      plugin: require('/Users/bsl/gatsby/wp-gatsby/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bsl/gatsby/wp-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-44052415-1","head":true},
    },{
      plugin: require('/Users/bsl/gatsby/wp-gatsby/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ikadatic.com"},
    },{
      plugin: require('/Users/bsl/gatsby/wp-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bsl/gatsby/wp-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
