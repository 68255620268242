/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.scss'

require('typeface-notosans-jp')
require('typeface-quicksand')
require('typeface-megrim')

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `ブログが更新されました。 ` + `最新情報を読み込みますか？`,
  )

  if (answer === true) {
    window.location.reload()
  }
}
